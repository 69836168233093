.about-page {
    p {
        text-align: left;
    }
    .outer-link {
        a {
            color: #0c7bbb;
            text-decoration: underline;
            font-size: 18px;
        }
    }
    &.commonWrapper {
        max-width: 614px;
        padding: 0;
    }
    .pl{
        padding-left: 0;
        @media (min-width: 576px){
            padding-left: 16px;
        }
    }
}
.mb-32{
    margin-bottom: 32px!important;
}
.mb-24{
    margin-bottom: 24px!important;
}
.mb-48{
    margin-bottom: 48px!important;
}
.mt-8{
    margin-top: 8px!important;
}
.mt-32{
    margin-top: 32px!important;
}
.mt-40{
    margin-top: 40px!important;
}
.mt-48{
    margin-top: 48px!important;
}
