@import "../../../variable.scss";

.assesmentWrapper {
    margin-top: -30px;
    @media (min-width: 768.98px){
        margin-top: -96px;
    }
    .assesment-steps {
        &_topbar {
            background: $highlight;
            border-bottom: 1px solid $highlightBorder;
            margin-left: -16px;
            margin-right: -16px;
            width: calc(100% + 32px);
            padding: 0 24px;

            &_inner {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 0;

                a {
                    color: $primaryColor;
                }

                h6 {
                    margin: 0;
                    font-size: $f-18;
                    font-weight: $fw-600;
                    display: flex;
                }
                .help-link{
                    border: 4px solid #3484C9;
                    border-radius: 100px;
                    padding: 7px 13px;
                    display: flex;
                    align-items: center;
                    font-size: $f-18;
                    font-weight: $fw-500;
                    grid-gap: 8px;
                    color: $linkColor;
                }
            }
            @media (min-width: 768.98px){
                margin-left: 0;
                margin-right: 0;
                width: 100%;
                padding: 0;
            }
        }
        &_content{
            margin-top: 16px;
            .nav-tabs{
                border: none;
                justify-content: center;
                grid-gap: 2px;
                li{
                    button{
                        font-size: $f-16;
                        border: none;
                        background: $TabColor;
                        width: 67px;
                        height: 16px;
                        border-radius: 0;
                        font-weight: $fw-400;
                        white-space: nowrap;
                        line-height: 48px;
                        text-align: left;
                        padding-left: 0;
                        color: $primaryColor;
                        &.active,&.done{
                            background: $primaryColor!important;
                        }
                        @media (min-width: 768.98px){
                            width: 120px;
                        }
                    }
                    &:first-child{
                        button{
                            border-radius: 36px 0 0 36px!important;
                        }
                    }
                    &:last-child{
                        button{
                            border-radius: 0 36px 36px 0;
                        }
                    }
                }
            }
        }
    }
}