@import "../../../variable.scss";

.commonWrapper {
    &.helpCrisis {
        max-width: 643px;

        p {
            font-size: $f-18;
            color: $blackColor;
            font-weight: $fw-400;
            margin-top: 32px;
            text-align: left;
            @media (min-width: 767px){
                text-align: center;
            }
        }

        .accountSub {
            li {
                padding-bottom: 16px;
                padding-top: 16px;

                .left-info {
                    display: flex;
                    flex-direction: column;
                    grid-gap: 8px;
                    font-size: $f-18;
                    font-weight: $fw-600;

                    p {
                        margin: 0;
                        font-size: $f-18;
                        font-weight: $fw-600;
                        text-align: left;
                        white-space: normal;
                        overflow: visible;
                        -webkit-box-orient: horizontal;
                    }
                }
                a.tel{
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }
    }
}