@import "../../../variable.scss";

.assesment-container{
    max-width: 608px;
    margin: auto;
    .main-info{
        display: flex;
        align-items: flex-start;
        grid-gap: 20px;
        margin-top: 37px;
        margin-bottom: 32px;
        font-size: $f-18;
        font-weight: $fw-400;
        p{
            line-height: 30px;
            margin: 0;
        }
        img{
            width: 73px;
            height: 83px;
        }
    }
    .time-info{
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        justify-content: center;
        grid-gap: 8px;
        font-size: $f-16;
        font-weight: $fw-400;
    }
}