.forgotPassword{
    max-width: 100%;
    margin: auto;
    text-align: right;
    font-size: 14px;
    margin-bottom: 5px;
    transform: translateY(-14px);
    color: #3484c9;
    @media (min-width: 768.98px){
        max-width: 400px;
    }
}
.password-main-div{
    position: relative;
    cursor: pointer;
    .eye-icon{
        position: absolute;
        top: 2px;
        right: 17px;
        font-size: 30px;
        svg{
            width: 20px;
        }
    }
}
