@import "../../../variable.scss";

.commonWrapper {
    p {
        font-size: $f-18;
        color: $blackColor;
        strong {
            color: $primaryColor;
            font-size: $f-20;
        }
    }
    a {
        text-decoration: none;
    }
    .circle-btn {
        border: 4px solid #3484c9;
        border-radius: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        cursor: pointer;
    }
    .accountSub{
        a.journal-link{
            padding: 16px 0;
            p{
                margin-bottom: 0;
            }
            small{
                font-weight: 400;
            }
            .rotate{
                transform: rotate(180deg);
            }
        }
    }
}

.sliding-btns{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $f-18;
    font-weight: $fw-600;
    a{
        display: flex;
        align-items: center;
        grid-gap: 8px;
        color: $primaryColor!important;
        &:last-child{
            img{
                transform: rotate(180deg);
            }
        }
    }

}

.left-info {
    display: flex;
    flex-direction: column;
    //grid-gap: 8px;
    font-size: $f-18;
    font-weight: $fw-600;

    p {
        margin: 0;
        font-size: $f-18;
        font-weight: $fw-600;
        text-align: left;
        display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
overflow: hidden;
    }
}

.react-datepicker__day--highlighted-custom-x{
    position: relative;
}
.react-datepicker__day--highlighted-custom-x::before{
    content: '';
    height: 5px;
    width: 5px;
    background-color: #d87f17;
    border-radius: 50%;
    position: absolute;
    bottom: 3px;
    right: 5px;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text{
    background-color: white;
}

.react-datepicker__month{
    background-color: #e1e1e1;
}

.react-datepicker__header{
    background-color: #ffffff !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    margin: 1.5px !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
    background-color: #3484c9 !important;
    border-radius: 0px !important;
}
.partnerWith {
    margin-top: 32px;
    P {
        font-size: $f-14;
        margin-bottom: 0;
    }
}