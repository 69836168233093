@import "../../../variable.scss";

$link-color: #0c7bbb;

a.custom-link {
    color: $link-color;
    text-decoration: none;
    cursor: pointer;
  }

.userWrapper {
    .commonWrapper p {
        text-align: left;
    }
    .form-check {
        .form-check-input {
            margin-left: 0;
        }
        label {
            width: auto;
            margin-left: 28px;
        }
    }

    a {
        text-decoration: none;
        color: inherit;
    }
    .multiSelect {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-top: 2px;
        margin-bottom: 2px;
        .custom-checkbox {
            display: flex;
            align-items: center;
            margin: 0.3rem 0.4rem;
            cursor: pointer;
            input[type="checkbox"] {
                display: none;
            }
            span {
                border: 2px solid #999999;
                border-radius: 24px;
                background: transparent;
                color: $primaryColor;
                margin: 0;
                padding: 6px 12px;
                font-weight: 500;
                font-size: 18px;
                margin-left: 0;
                min-width: 150px;
                text-align: center;
                &:hover {
                    background: #18427b;
                    border-color: #18427b;
                    color: white;
                }
            }
            @media (max-width: 767px) {
                span {
                  min-width: fit-content;
                  padding: 6px 12px;
                }
              }
            input[type="checkbox"]:checked + span {
                background: #0060c4;
                border-color: #0060c4;
            }
        }
        .custom-checkbox.selected span {
            color: white;
          }
    }
}
  
.edit-detail {
    .mainData{
        margin-top: 40px;
        p{
            margin-bottom: 16px!important;
        }
    }
    .button-border {
        justify-content: start;
        button {
            width: 94px;
            height: 48px;
            font-weight: 500;
            font-size: $f-16;
            margin-bottom: 10px;
        }
    }
    .form-check-input{
        border-color:#56A741;
        transform: scale(1.5);
        }
        .form-check-input:checked{
            border-color:#56A741
        }
        a {
            color: #3484C9;
        }
        .site-primary-btn{
            width: 100%;
        }
}

.error-message {
    color: red; /* Error message text color */
    font-size: 12px; /* Font size for the error message */
    margin-top: 4px; /* Add margin to separate it from the input */
  }


