@import "../../../variable.scss";

.commonWrapper {
    max-width: 608px;

    h3 {
        display: flex;
        grid-gap: 8px;

        .heading {
            display: flex;
            flex-direction: column;

            span:last-child {
                font-size: 18px;
            }
        }
    }

    .moods-list-info {
        h2 {
            text-align: left;
            font-size: $f-18;
            font-weight: $fw-600;
            line-height: 30px;
        }

        .moods-category {
            display: flex;
            grid-gap: 20px 10px;
            flex-wrap: wrap;
            margin-top: 32px;
            margin-bottom: 32px;
            justify-content: center;

            .mood-option {
                flex: 0 0 31%;
                cursor: pointer;
                @media (min-width: 576px){
                    flex: 0 0 32%;
                }

                input {
                    position: absolute;
                    left: -99999px;
                    opacity: 0;
                }

                label {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    grid-gap: 4px;
                    font-size: 16px;
                    font-weight: 600;
                    padding: 8px;
                    border-radius: 4px;
                    background: $white;
                    cursor: pointer;
                }

                input:checked+label {
                    background: $activeGreen;
                    color: $white;

                    img {
                        filter: invert(1) brightness(2);
                    }
                }
            }
        }
    }

    .mood-selction-info{
        p{
            display: flex;
            margin-bottom: 32px;
            grid-gap: 8px;
            align-items: center;
            font-size: $f-18;
            font-weight: $fw-400;
        }
        form{
            margin-bottom: 32px;
            label{
                font-size: $f-18;
                font-weight: $fw-600;
                width: 100%;
            }
            .form-control{
                border: 2px solid #999999;
                border-radius: 4px;
                font-size: $f-16;
                font-weight: $fw-400;
                padding: 9px;
                color: #0F1D2B;
            }
        }
    }
}
.journal-info{
    margin-top: 50px!important;
}