@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
@import url('../src/assets/scss/style.scss');

@import "./variable.scss";

body {
    font-family: 'Poppins', sans-serif !important;
}

.mainWrapper {
    .innerWrapper {
        padding: 32px 16px 120px;
        min-height: calc(100vh - 112px);
        //border-top: 1px solid #eee;
        @media (min-width:768px) {
            padding: 82px 30px 63px;
        }
    }
}

[class^='col'].custom-gutter,
[class*=' col'].custom-gutter {
    padding-left: 8px;
    padding-right: 8px;
}

a {
    text-decoration: none;
    color: inherit;
}

.global-font-body {
    font-size: $f-14;
}

@media (max-width: 768.98px) {
    .container {
        padding: 0 !important;
    }
}

.offcanvas-backdrop.show {
    display: none;
}

.btn-close {
    display: none;
}

.react-datepicker {
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 327px;
    margin: auto;
    height: 378px;
    background: #fff;
    border: none !important;
    font-family: "Poppins", sans-serif !important;

    .react-datepicker__navigation--previous,
    .react-datepicker__navigation--next {
        top: 19px;
        z-index: 999;
    }

    .react-datepicker__month-container {
        padding: 10px 7px;
        position: relative;
        background: #fff;
        box-shadow: 0px 2px 16px rgb(2 48 71 / 10%);
        border-radius: 8px !important;
        z-index: 99;
    }

    .react-datepicker__header {
        border: none !important;
    }

    .react-datepicker__day-name {
        width: 40px;
        font-size: 15px;
    }

    .react-datepicker__day-names {
        margin-bottom: -6px !important;
        margin-top: 20px;
    }

    .react-datepicker__day {
        width: 40px;
        height: 40px;
        font-size: 15px;
        font-weight: 600;
        line-height: 40px;
    }

    &:before {
        content: '';
        background: rgba(0, 0, 0, 0.4);
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
@media (min-width: 576px){
    .react-datepicker {
        max-width: 400px;
        .react-datepicker__day-name {
            width: 50px;
            font-size: 16px;
        }
        .react-datepicker__day {
            width: 50px;
            height: 50px;
            font-size: 18px;
            font-weight: 600;
            line-height: 50px;
        }
    }
}
@media (max-width: 576px){
    .font-18{
        font-size: 18px!important;
    }
}