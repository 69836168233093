@import "../../variable.scss";

.forgotPasswordp {
    p {
        
        margin-right: auto;
        max-width: 665px;
        margin-left: auto;
        @media (min-width: 768.98){
            max-width: 400px;
        }
    }
}
.password-main-div{
    position: relative;
    cursor: pointer;
    .eye-icon{
        position: absolute;
        top: 2px;
        right: 17px;
        font-size: 30px;
    }
}