@import "../../variable.scss";

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    .loader {
        width: 104px;
        height: 104px;
        display: inline-block;
        padding: 0px;
        border-radius: 100%;
        border: 10px solid;
        border-top-color: #0F1D2B;
        border-bottom-color: #0F1D2B;
        border-left-color: #C4C4C4;
        border-right-color: #C4C4C4;
        -webkit-animation: loader 2s ease-in-out infinite;
        animation: loader 2s ease-in-out infinite;
    }

    @keyframes loader {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes loader {
        from {
            -webkit-transform: rotate(0deg);
        }

        to {
            -webkit-transform: rotate(360deg);
        }
    }
}