@import "../../../variable.scss";

.commonWrapper {
    max-width: 570px;
    margin: auto;
    padding: 0 1rem;
    h3 {
        font-weight: $fw-600;
        font-size: 28px;
        line-height: 40px;
        color: $primaryColor;
        grid-gap: 0;
        @media (min-width: 768.98px){
            font-size: 36px;
        }
    }
    p {
        font-size: $f-16;
        color: $blackColor;
        strong {
            color: $primaryColor;
            font-size: $f-20;
        }
        @media (min-width: 768.98px){
            font-size: $f-18;
        }
    }
    a {
        text-decoration: none;
        //color: inherit;
    }
    .circle-btn {
        border: 4px solid #3484c9;
        border-radius: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        cursor: pointer;
    }
    .accountSub{
        a{
            p{
                margin-bottom: 0;
            }
            small{
                font-weight: 400;
            }
        }
    }
}

.partnerWith {
    margin-top: 32px;
    P {
        font-size: $f-14;
        margin-bottom: 0;
    }
}

.faLeftclass{
    transform: rotate(0deg) !important;
}
.mood-selction-info{
    margin-top: 48px;
    margin-bottom: 56px;
    .selected-option-info{
        margin-bottom: 24px;
    }
    label{
        font-size: $f-18;
        font-weight: $fw-600;
        margin-bottom: 8px;
    }
    p{
        font-size: $f-16;
        font-weight: $fw-400;
    }
}
.mt-40{
    margin-top: 40px!important;
}