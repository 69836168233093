@import "../../variable.scss";

.symptom-checker{
    transform: translateY(-96px);
    &_blueBar{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;
        background: #245EB2;
        border-bottom: 1px solid #C4C4C4;
        color: #fff;
        font-size: $f-18;
        font-weight: $fw-500;
    }
    &_section{
        background: #F1F4F5;
        padding: 50px 0;
        &_inner{
            h2{
                font-weight: $fw-600;
            }
            p{
                margin-top: 30px;
                margin-bottom: 20px;
                font-weight: $fw-600;
            }
            ul{
                padding-left: 1.2rem;
                line-height: 2;
            }
            .start-btn{
                background: #377C42;
                padding: 10px;
                color: #fff;
                border-radius: 4px;
                margin: 10px 0 15px;
            }
            hr{
                width: 100px;
                border-width: 3px;
            }
            .bottom-info{
                h2{
                    font-size: $f-18;
                    margin-top: 30px;
                }
                p{
                    margin-top: 20px;
                    margin-bottom: 20px;
                    font-weight: 400;
                }
            }
        }
    }
}
