@import "../../variable.scss";

.userWrapper{
    width: 100%;
    max-width: 100%;
    .menu-column{
        margin-top: 32px;
        margin-bottom: 32px;
        .menu-inner-data{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            //align-items: center;
            grid-gap: 0 24px;
            text-align: center;
            height: 100%;
            border-bottom: 2px solid #E1E1E1;
            padding-bottom: 24px;
            @media (min-width: 768.98px){
                border: 2px solid #E1E1E1;
                padding: 68px 45px;
            }
            .info{
                display: flex;
                align-items: center;
                flex-direction: column;
                grid-gap: 4px;
                .icon{
                    height: 73px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        width: 65px;
                        display: flex;
                        margin: auto;
                        height: 100%;
                    }
                }
                label{
                    font-size: $f-20;
                    font-weight: $fw-600;
                    line-height: 38px;
                    @media (min-width: 768.98px){
                        font-size: $f-28;
                    }
                }
                p{
                    font-size: $f-16;
                    font-weight: $fw-400;
                    line-height: 28px;
                    text-align: center;
                }
            }
            button{
                width: 100%;
            }
        }
    }
}
.custom-gutter{
    margin-bottom: 16px;
}

.row-center {
    display: flex;
    justify-content: center;
}

.custom-pad{
    padding: 0;
    @media (min-width: 576px){
        padding: 0 30px;
    }
}