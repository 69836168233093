@import "../../variable.scss";

.navbar{
    button.navbar-toggler{
        border: none;
        &:focus{
            box-shadow: none;
        }
    }
    .navbar-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: $f-18;
        grid-gap: 10px;
    }
}
.site-header{
    padding: 10px 16px!important;
    @media (min-width: 576px){
        padding: 10px 0!important;
    }
}
.site-menu{
    width: 100%;
    max-width: 400px;
    background: #fff;
    box-shadow: 0px 2px 4px rgb(2 48 71 / 15%);
    height: 100vh;
    padding: 16px!important;
    //height: 100%;
    margin-top: 80px;
    .right-sidebar-default{
        display: flex;
        flex-direction: column;
        grid-gap: 24px;
        .nav-link{
            padding: 0;
            button{
                width: 368px;
            }
            &.menu-item{
                padding: 0 10px;
                display: flex;
                align-items: center;
                grid-gap: 20px;
                text-decoration: none;
                .icon{
                    img{
                        width: 30px;
                    }
                }
                .menu-label{
                    font-size: $f-20;
                    font-weight: $fw-400;
                    
                }
            }
        }
    }
    .right-sidebar-default-bottom{
        margin-top: 68px;
        .nav-link{
            .menu-label{
                font-size: $f-20;
                font-weight: $fw-400;
                padding-left: 60px;
            }
        }
    }
}

.menu-item{
    padding: 0 10px;
    display: flex;
    align-items: center;
    grid-gap: 20px;
    text-decoration: none;
    .icon{
        img{
            width: 30px;
        }
    }
    .menu-label{
        font-size: $f-20;
        font-weight: $fw-400;
        
    }
}
@media (max-width:768px) {
.navbar-brand{
    img{
        max-width: 100px;
    }
}
.site-header {
    position: fixed;
    right: 0;
    left: 0;
    background: #fff;
    border-bottom: 1px solid #ddd;
    z-index: 9;
    padding: 10px 16px!important;
}
}
.modal{
    position: fixed !important;
    z-index: 2 !important;
    bottom: 0px !important;
    right: 0px !important;
}
