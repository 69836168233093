@import "../../variable.scss";

// Define the link color
$link-color: #0c7bbb;

// Style the anchor tag with the link color
a.custom-link {
  color: $link-color;
  text-decoration: none;
  cursor: pointer;
}

.userWrapper{
    max-width: 100%;
    margin: auto;
    h2{
        font-size: $f-28;
        font-weight: $fw-600;
        text-align: left;
        margin-bottom: 16px;
        line-height: 32px;
        @media (min-width: 768.98px){
            font-size: $f-36;
            text-align: center;
        }
    }
    p{
        font-size: $f-18;
        font-weight: $fw-400;
        text-align: left;
        margin-bottom: 32px;
        @media (min-width: 768.98px){
            text-align: center;
        }
    }
    .formContainer{
        max-width: 100%;
        margin: auto;
        
         @media (min-width: 768.98px){
            padding: 0 1rem;
            max-width: 430px
        }
        .form-label{
            font-size: $f-18;
            font-weight: $fw-600;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .form-control{
            border: 2px solid #999999;
            border-radius: 4px;
            height: 48px;
            font-size: $f-18;
            font-weight: $fw-400;
            padding: 9px;
            color: #0F1D2B;
        }
        .formMargin{
            margin-bottom: 24px;
        }
    }
    .NoAccount{
        font-size: $f-18;
        font-weight: $fw-600;
        margin-top: 32px;
        margin-bottom: 16px;
        color: #000;
        display: block;
        text-align: center;
        text-decoration: none;
    }
    .partnerShip{
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p{
            font-size: $f-14;
            font-weight: $fw-400;
            color: #000;
            margin: 0;
        }
    }
}
.password-main-div{
    position: relative;
    cursor: pointer;
    .eye-icon{
        position: absolute;
        top: 2px;
        right: 17px;
        font-size: 30px;
    }
}