.about-page {
    p {
        text-align: left;
    }
    .outer-link {
        a {
            color: #0c7bbb;
            text-decoration: underline;
            font-size: 18px;
          display: block;
          margin-bottom: 1rem;
        }
    }
}
