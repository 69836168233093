@import "../../../../variable.scss";

.steps-submitted{
    &_inform{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $f-28;
        grid-gap: 8px;
        font-weight: $fw-600;
        margin-bottom: 32px;
    }
    &_description{
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        grid-gap: 32px;
        font-size: $f-18;
        font-weight: $fw-600;
        margin-bottom: 32px;
        p{
            margin: 0;
        }
    }
}