@import "../../variable.scss";

.commonWrapper {
    max-width: 570px;
    margin: auto;
    padding: 0 1rem;
    h2 {
        font-weight: $fw-600;
        font-size: 28px;
        line-height: 40px;
        text-align: left;
        margin-bottom: 40px;
        color: $primaryColor;
        @media (min-width: 576px){
            text-align: center;
            font-size: 36px;
            margin-bottom: 48px;
        }
    }
    p {
        font-size: $f-18;
        color: $blackColor;
        margin-bottom: 16px;
        strong {
            color: $primaryColor;
            font-size: $f-20;
        }
    }
}
.accountSub {
    list-style-type: none;
    padding: 0;
    li {
        border-bottom: 1px solid #e1e1e1;
        a {
            font-size: $f-18;
            color: $primaryColor;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 0;
            font-weight: $fw-600;
        }
    }
}
.partnerWith{
    margin-top: 32px;
    P{
    font-size: $f-14;
    margin-bottom: 0;
    }
}
.text-start{
    .m-0{
        margin: 0!important;
    }
}

@media (max-width:675px) {
    .commonWrapper{
        padding: 0!important;
        p
         h2{
        font-size: 1.6rem;
        margin-bottom: 2rem;
        text-align: left;
    }
    p{
        font-size: 1rem;
    }
    }
}
.account-mb{
    margin-bottom: 8px;
}