@import "../../../../variable.scss";

.checkbox-group{
    .check-row{
        margin-bottom: 32px;
    }
}
.shift-top{
    transform: translateY(-16px)
}
.drinks-options{
    max-width: 400px;
    margin: auto;
    font-size: $f-16;
    font-weight: $fw-400;
    margin-bottom: 32px;
    p{
        text-align: left;
    }
    .list-group{
        flex-direction: row;
        grid-gap: 40px;
        justify-content: space-between;
        .list-group-item{
            border: none;
            padding: 0;
            .list-inner{
                display: flex;
                flex-direction: column;
                align-items: center;
                grid-gap: 8px;
                span{
                    max-width: 106px;
                }
            }
        }
    }
}