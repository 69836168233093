@import "../../../../variable.scss";

.assesment-steps_marking{
    &_inner{
        margin-top: 70px!important;
        max-width: 608px;
        margin: auto;
        &_options{
            max-width: 608px;
            display: flex;
            flex-direction: column;
            grid-gap: 16px;
            justify-content: center;
            margin: auto;
            .option-row{
                border: 4px solid #626262;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 23px 32px;
                font-size: $f-18;
                font-weight: $fw-600;
                color: $primaryColor!important;
                img{
                    transform: rotate(180deg);
                }
            }
        }
    }
}